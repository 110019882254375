import React, { useState, useEffect, useRef } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import './modalProspect.css'; // Assurez-vous d'avoir un fichier CSS pour styliser votre modale
import StarRating from '../StarRating';
import { useAuth } from '../../context/AuthContext'
import YesNoField from '../YesNoField';

const ModalProspect = ({ prospect, isOpen, onClose,refresh }) => {

  const { userConnected } = useAuth();

  const [heureDebutAppel, setHeureDebutAppel] = useState(null);
  const [heureFinAppel, setHeureFinAppel] = useState(null);
  const [noteAppel, setNoteAppel] = useState(0);
  const [dateAppel, setDateAppel] = useState(null);
  const [isSiteExist, setIsSiteExist] = useState(null);
  const [isClientOk, setIsClientOk] = useState(null);
  const [isClientNotResponding, setIsClientNotResponding] = useState(null);
  const [contactProspect, setContactProspect] = useState('');
  const [bilanAppel, setBilanAppel] = useState('');
  const [isCalling, setIsCalling] = useState(false);
  const [callDuration, setCallDuration] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  useEffect(() => {
    // Initialiser la date de l'appel à la date du jour
    const today = new Date().toISOString().split('T')[0];
    setDateAppel(today);
  }, []);

  useEffect(() => {
    let timer;

    if (isCalling) {
      timer = setInterval(() => {
        setCallDuration((prevDuration) => prevDuration + 1); // Incrémenter la durée de l'appel
      }, 1000); // Met à jour toutes les secondes
    }

    return () => clearInterval(timer); // Nettoyer le timer lorsque l'appel se termine
  }, [isCalling]);

  // Fonction pour gérer le changement dans les champs de texte
  const handleChangeContactProspect = (e) => {
    setContactProspect(e.target.value);
  };

  const handleChangeBilanAppel = (e) => {
    setBilanAppel(e.target.value);
  };

  const handleNoteRating = (rating) => {
    setNoteAppel(rating);
  };

  const handleYesNoChangeSiteExist = (value) => {
    setIsSiteExist(value);
  };

  const handleYesNoChangeClientOk = (value) => {
    setIsClientOk(value);
  };

  const handleYesNoChangeClientNotResponding = (value) => {
    setIsClientNotResponding(value);
  };

  const getCurrentTime = () => {
    const currentTime = new Date();
    return currentTime.toLocaleTimeString('fr-FR', { hour12: false }); // Format 24 heures sans AM/PM
  };

  const handleStartRecording = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error('getUserMedia() n\'est pas supporté dans ce navigateur.');
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);
        setAudioBlob(audioBlob);
      };

      mediaRecorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Erreur lors de l\'accès au microphone :', error);
    }
  };

  const handleStopRecording = () => {
    mediaRecorderRef.current.stop();
    setIsRecording(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Créer un objet FormData pour envoyer les données, y compris le fichier audio
    const formData = new FormData();
  
    // Ajouter le fichier audio Blob en tant que "audioFile" (si un enregistrement existe)
    if (audioBlob) {
      const audioFile = new File([audioBlob], 'audioFile.wav', { type: 'audio/wav' });
      formData.append('audioFile', audioFile);
    }
  
    // Ajouter les autres données du formulaire
    formData.append('idProspect', prospect.idProspect);
    formData.append('idUser', userConnected.idUser);
    formData.append('dateAppelProspect', dateAppel);
    formData.append('heureDebutAppel', heureDebutAppel);
    formData.append('heureFinAppel', heureFinAppel);
    formData.append('contactAppel', contactProspect);
    formData.append('isSiteExist', isSiteExist);
    formData.append('noteAppel', noteAppel);
    formData.append('isProspectOk', isClientOk);
    formData.append('isProspectNotResponding', isClientNotResponding);
    formData.append('bilanAppel', bilanAppel);
  
    try {
      const response = await fetch('/api/prospection/appel/add', {
        method: 'POST',
        body: formData, // Envoi de formData avec les fichiers
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Nouvel appel ajouté :', data);
        refresh()
        onClose(); // Fermer la modale après soumission
      } else {
        console.error('Erreur lors de l\'ajout de l\'appel :', response.statusText);
      }
    } catch (error) {
      console.error('Erreur de réseau :', error);
    }
  };
  

  // Fonction pour démarrer l'appel
  const handleStartCall = () => {
    setHeureDebutAppel(getCurrentTime());
    setIsCalling(true);
    handleStartRecording();
    setCallDuration(0); // Réinitialiser la durée
  };

  // Fonction pour arrêter l'appel
  const handleStopCall = () => {
    setHeureFinAppel(getCurrentTime());
    setIsCalling(false);
    handleStopRecording();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>X</button>
        <h2>Informations de l'appel pour {prospect.raisonSocialeProspect}</h2>

        {/* QR Code basé sur le numéro de téléphone */}
        <div className="qr-code">
          <QRCodeCanvas value={`tel:${prospect.telephoneProspect}`} size={200} />
          <p>{prospect.telephoneProspect}</p>
        </div>

        {/* Afficher la durée de l'appel */}
        <div className="call-timer">
          <p>Durée de l'appel : {callDuration} secondes</p>
          {isRecording && <p>Enregistrement en cours ...</p>}
        </div>

        {/* Boutons pour démarrer et arrêter l'appel */}
        <div className="call-buttons">
          <button onClick={handleStartCall} disabled={isCalling}>
            Démarrer l'appel
          </button>
          <button onClick={handleStopCall} disabled={!isCalling}>
            Arrêter l'appel
          </button>
        </div>

        {/* Formulaire pour entrer les informations de l'appel */}
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="contactProspect">Nom et rôle de l'interlocuteur</label>
            <textarea
              name="contactProspect"
              id="contactProspect"
              cols="30"
              rows="10"
              onChange={handleChangeContactProspect}
            ></textarea>
          </div>
          <div>
            <YesNoField label="Le site existe-t-il ?" onChange={handleYesNoChangeSiteExist} />
          </div>
          <div>
            <YesNoField label="Le prospect est ok pour devenir client ?" onChange={handleYesNoChangeClientOk} />
          </div>
          <div>
            <YesNoField label="Le prospect est-il absent ?" onChange={handleYesNoChangeClientNotResponding} />
          </div>
          <div>
            <StarRating label="Evaluation de l'appel" onRatingSubmit={handleNoteRating} />
          </div>
          <div>
            <label htmlFor="bilanAppel">Bilan de l'appel</label>
            <textarea
              name="bilanAppel"
              id="bilanAppel"
              cols="30"
              rows="10"
              onChange={handleChangeBilanAppel}
            ></textarea>
          </div>

          {/* Lecture de l'audio */}
          {audioUrl && <audio controls src={audioUrl}></audio>}
        {
            !isRecording &&(<button type="submit">Enregistrer les informations</button>)
        }
          
        </form>
      </div>
    </div>
  );
};

export default ModalProspect;
