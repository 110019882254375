import React, { useState, useEffect, useCallback } from 'react';
import ModalProspect from '../../components/ModalProspect';
import "./prospection.css";

function Prospection() {
  const [prospects, setProspects] = useState([]);
  const [appels, setAppels] = useState([])
  const [nbTotalAppels, setNbTotalAppels] = useState(0); // Nombre total de prospects
  const [nbTotalProspects, setNbTotalProspects] = useState(0); // Nombre total de prospects
  const [typeEntreprise, setTypeEntreprise] = useState('');  
  const [ville, setVille] = useState('');  
  const [codePostal, setCodePostal] = useState(''); 
  
  const [page, setPage] = useState(1);
  const [pageAppels, setPageAppels] = useState(1)
  const [limit] = useState(5);
  const [limitAppels] = useState(5);
  const [selectedProspect, setSelectedProspect] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchAppels = useCallback(() => {
    const params = {
      page: pageAppels,
      limit: limitAppels
    };

    const queryString = new URLSearchParams(params).toString();

    fetch(`/api/prospection/appels?${queryString}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erreur lors de la requête API");
        }
        return response.json();
      })
      .then((data) => {
        setAppels(data.appels);
        setNbTotalAppels(data.nbTotalAppels);
      })
      .catch((error) => console.error('Erreur:', error));
  }, [pageAppels, limitAppels]); // Ajouter les dépendances nécessaires

  useEffect(() =>{
    fetchAppels()
  }, [fetchAppels]);


  const fetchProspects = useCallback(()=>{
    const params = {
        typeEntrepriseProspect: typeEntreprise,  
        villeProspect: ville,  
        codePostalProspect: codePostal,  
        page: page,
        limit: limit
      };
  
      // Convertir les paramètres en query string
      const queryString = new URLSearchParams(params).toString();
  
      // Requête à l'API PHP avec les paramètres dynamiques dans l'URL
      fetch(`/api/prospects?${queryString}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Erreur lors de la requête API");
          }
          return response.json();
        })
        .then((data) => {
          setProspects(data.prospects);
          setNbTotalProspects(data.nbTotalProspects);
        })
        .catch((error) => console.error('Erreur:', error));
  },[typeEntreprise, ville, codePostal, page, limit])

  useEffect(() => {
    // Créer un objet avec les paramètres de requête dynamiques
    fetchProspects()
  }, [fetchProspects]);

  // Gestion des pages
  const handlePreviousPageAppel = () => {
    if (pageAppels > 1) {
      setPageAppels(pageAppels - 1);
    }
  };

  const handleNextPageAppel = () => {
    if (pageAppels < Math.ceil(nbTotalAppels / limitAppels)) {
      setPageAppels(pageAppels + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < Math.ceil(nbTotalProspects / limit)) {
      setPage(page + 1);
    }
  };

  const handleTypeChange = (e) => setTypeEntreprise(e.target.value);
  const handleCodePostalChange = (e) => setCodePostal(e.target.value);
  const handleVilleChange = (e) => setVille(e.target.value);
// Fonction pour ouvrir la modale avec un prospect sélectionné
const handleOpenModal = (prospect) => {
    setSelectedProspect(prospect);
    setIsModalOpen(true);
  };

  // Fonction pour fermer la modale
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProspect(null);
  };

  // Fonction pour traiter la soumission du formulaire
  const handleSubmit = (callDetails) => {
    console.log("Informations récupérées : ", callDetails);
    // Ici, tu peux envoyer les données au serveur ou les traiter
  };
  const totalPages = Math.ceil(nbTotalProspects / limit); // Calcul du nombre total de pages
  const totalPagesAppels = Math.ceil(nbTotalAppels / limit);

  return (
    <main>
        <h1>{nbTotalAppels} Appels trouvés</h1>
      <div>
        <button onClick={handlePreviousPageAppel} disabled={pageAppels === 1}>Précédent</button>
        Page {pageAppels} sur {totalPagesAppels}
        <button onClick={handleNextPageAppel} disabled={pageAppels >= totalPagesAppels}>Suivant</button>
      </div>
        <table>
            <thead>
                <tr>
                    <th>Raison sociale du prospect</th>
                    <th>Date de l'appel</th>
                    <th>Heure début appel</th>
                    <th>Heure fin appel</th>
                    <th>Nom et rôle du contact</th>
                    <th>Site existant ?</th>
                    <th>Prospect ok ?</th>
                    <th>Prospect absent ?</th>
                    <th>Evaluation appel</th>
                    <th>Bilan appel</th>
                    <th>Enregistrement</th>
                </tr>
            </thead>
            <tbody>
            {appels && appels.length > 0 ? (
                appels.map((appel) => (
                <tr key={appel.idAppelProspect}>
                    <td>{appel.prospect.raisonSocialeProspect}</td>
                    <td>{appel.dateAppelProspect}</td>
                    <td>{appel.heureDebutAppel}</td>
                    <td>{appel.heureFinAppel}</td>
                    <td>{appel.contactAppel}</td>
                    <td>{appel.isSiteExist}</td>
                    <td>{appel.isProspectOk}</td>
                    <td>{appel.isProspectNotResponding}</td>
                    <td>{appel.noteAppel}</td>
                    <td>{appel.bilanAppel}</td>
                    <td><audio controls>
                    <source src={`/api/prospection/appel/audio?idAppel=${appel.idAppelProspect}`} type="audio/wav" />
                    Votre navigateur ne supporte pas la lecture des fichiers audio.
                    </audio></td>
                </tr>
                ))
            ) : (
                <tr>
                <td colSpan="9">Aucun appel trouvé</td>
                </tr>
            )}
            </tbody>
        </table>
        
      <h1>{nbTotalProspects} Prospects trouvés</h1>
      <div>
        <button onClick={handlePreviousPage} disabled={page === 1}>Précédent</button>
        Page {page} sur {totalPages}
        <button onClick={handleNextPage} disabled={page >= totalPages}>Suivant</button>
      </div>
      <table>
        <thead>
          <tr>
            <th>Raison Sociale</th>
            <th>Type d'entreprise<input type="text" name="typeEntreprise" id="typeEntreprise" onChange={handleTypeChange} /></th>
            <th>Adresse</th>
            <th>Code Postal<input type="text" name="codePostalEntreprise" id="codePostalEntreprise" onChange={handleCodePostalChange} /></th>
            <th>Ville<input type="text" name="villeEntreprise" id="villeEntreprise" onChange={handleVilleChange} /></th>
            <th>Téléphone</th>
          </tr>
        </thead>
        <tbody>
  {prospects && prospects.length > 0 ? (
    prospects.map((prospect) => (
      <tr key={prospect.idProspect} onClick={() => handleOpenModal(prospect)}>
        <td>{prospect.raisonSocialeProspect}</td>
        <td>{prospect.typeEntrepriseProspect}</td>
        <td>{prospect.adresseProspect}</td>
        <td>{prospect.codePostalProspect}</td>
        <td>{prospect.villeProspect}</td>
        <td>{prospect.telephoneProspect}</td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="6">Aucun prospect trouvé</td>
    </tr>
  )}
</tbody>

      </table>
      {selectedProspect && (
        <ModalProspect
          prospect={selectedProspect}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSubmit={handleSubmit}
          refresh={fetchAppels}
        />
      )}
    </main>
  );
}

export default Prospection;
