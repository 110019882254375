import React, { createContext, useContext, useState } from 'react';

// Créer le contexte
const AuthContext = createContext();

// Hook pour utiliser le contexte plus facilement
export const useAuth = () => {
  return useContext(AuthContext);
};

// Fournisseur du contexte
export const AuthProvider = ({ children }) => {
  // Vérifier s'il existe déjà des données de l'utilisateur dans localStorage
  const storedUser = localStorage.getItem('userConnected') ? JSON.parse(localStorage.getItem('userConnected')) : null;

  const [userConnected, setUserConnected] = useState(storedUser);

  // Fonction pour connecter l'utilisateur
  const login = (user) => {
    setUserConnected(user);
    // Stocker l'utilisateur dans localStorage
    localStorage.setItem('userConnected', JSON.stringify(user));
  };

  // Fonction pour déconnecter l'utilisateur
  const logout = () => {
    setUserConnected(null);
    // Supprimer les données de l'utilisateur dans localStorage
    localStorage.removeItem('userConnected');
  };

  // Valeur à fournir à tout composant qui utilise ce contexte
  const value = {
    userConnected,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
