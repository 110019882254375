import React, { useState } from "react";

const YesNoField = ({ label, onChange }) => {
  const [value, setValue] = useState(null); // Valeur sélectionnée

  const handleChange = (event) => {
    const selectedValue = event.target.value === "true"; // Convertir en booléen
    setValue(selectedValue);
    onChange(selectedValue); // Appel de la fonction parent avec la nouvelle valeur
  };

  return (
    <div>
      <label>{label}</label>
      <div>
        <label>
          <input
            type="radio"
            name="yesNo"
            value="true"
            checked={value === true}
            onChange={handleChange}
          />
          Oui
        </label>
        <label style={{ marginLeft: "10px" }}>
          <input
            type="radio"
            name="yesNo"
            value="false"
            checked={value === false}
            onChange={handleChange}
          />
          Non
        </label>
      </div>
    </div>
  );
};

export default YesNoField;
