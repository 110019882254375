import "./footer.css"

function Footer() {
  return (
    <footer className="footer">
      <p className="footer-text">Copyright 2024 Bonnard Web Editions</p>
    </footer>
  )
}
export default Footer
