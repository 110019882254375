import { NavLink } from "react-router-dom";
import logo from "../../assets/logo.png";
import "./header.css";
import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext'
function Header() {
    const { userConnected, login, logout } = useAuth()
    //const [userConnected, setUserConnected] = useState(null); // Stocker l'utilisateur connecté
    const [mailUser, setMailUser] = useState(""); // Stocker l'email
    const [passwordUser, setPasswordUser] = useState(""); // Stocker le mot de passe

    // Fonction pour gérer la connexion
    const handleConnexion = async () => {
        // Créer un objet avec les informations de connexion
        const data = new URLSearchParams();
        data.append('mailUser', mailUser);
        data.append('passwordUser', passwordUser);

        try {
            // Envoyer la requête au serveur pour vérifier les informations de connexion
            const response = await fetch('/api/user/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: data,
            });

            const result = await response.json();

            // Vérifier si la connexion est réussie
            if (result.connection === "OK") {
                login(result.userConnected);  // Mettre à jour l'état de connexion via le contexte
            } else {
                alert("Connexion refusée. Vérifiez vos identifiants.");
            }
        } catch (error) {
            console.error("Erreur lors de la connexion:", error);
        }
    };

    // Fonction pour gérer la déconnexion
  

    return (
        <nav className="main-nav">
            <NavLink className="main-nav-logo" to="/">
                <img className="main-nav-logo-image" src={logo} alt="Logo" />
            </NavLink>
            <NavLink className="main-nav-item" to="/">ACCUEIL</NavLink>
            <NavLink className="main-nav-item" to="/a_propos">A PROPOS</NavLink>
            <NavLink className="main-nav-item" to="/offres">OFFRES</NavLink>
            {userConnected && (userConnected.roleUser === "superAdmin" || userConnected.roleUser === "commercial") && (
                <NavLink className="main-nav-item" to="/prospection">PROSPECTION</NavLink>
            )}
            {/* Si l'utilisateur est connecté, afficher un message de bienvenue et un bouton de déconnexion */}
            {userConnected ? (
                <div>
                    <span>Bienvenue, {userConnected.mailUser}</span>
                    <input type="button" value="DÉCONNEXION" onClick={logout} />
                </div>
            ) : (
                // Sinon, afficher le formulaire de connexion
                <div>
                    <label htmlFor="mailUser">EMAIL</label>
                    <input
                        type="email"
                        name="mailUser"
                        id="mailUser"
                        value={mailUser}
                        onChange={(e) => setMailUser(e.target.value)}
                    />
                    <label htmlFor="passwordUser">MOT DE PASSE</label>
                    <input
                        type="password"
                        name="passwordUser"
                        id="passwordUser"
                        value={passwordUser}
                        onChange={(e) => setPasswordUser(e.target.value)}
                    />
                    <input type="button" value="CONNEXION" onClick={handleConnexion} />
                </div>
            )}
        </nav>
    );
}

export default Header;
