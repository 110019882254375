// PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const PrivateRoute = ({ allowedRoles, children }) => {
    const { userConnected } = useAuth();

    // Si l'utilisateur n'est pas connecté ou son rôle n'est pas autorisé
    if (!userConnected || !allowedRoles.includes(userConnected.roleUser)) {
        return <Navigate to="/" />;  // Rediriger vers l'accueil ou une autre page
    }

    // Sinon, rendre le composant enfant
    return children;
};

export default PrivateRoute;
